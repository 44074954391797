import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import LeadForm from "./LeadForm";
import LeadSuccess from "./LeadSuccess";

const App = ({
    locale,
    formTypes,
    drives,
    countries,
    navigationAreas,
    models,
    dealerObjects,
    salesPersonObjects,
    lead,
    event,
    selectedDealer
}) => {

    console.log("event", event);
    console.log("selected_dealer", selectedDealer)

    return (
        <Router>
            <Switch>
                <LeadForm
                    path="/"
                    locale={locale}
                    formTypes={formTypes}
                    drives={drives}
                    countries={countries}
                    navigationAreas={navigationAreas}
                    models={models}
                    dealerObjects={dealerObjects}
                    lead={lead}
                    event={event}
                    salesPersonObjects={salesPersonObjects}
                    signature={true}
                    doubleOptin={false}
                    selectedDealer={selectedDealer}
                />
            </Switch>
        </Router>
    );
};

export default App;
