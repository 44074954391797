import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ReactEcharts from "echarts-for-react"
import LeadsByCountry from "./LeadsByCountry";
import LeadsByTime from "./LeadsByTime";
import UsersByActivity from "./UsersByActivity";
import OtherLeadsByTime from "./OtherLeadsByTime";
import LeadsByCountryDonut from "./LeadsByCountryDonut";
import DealersByActivity from "./DealersByActivity";
import LeadsQualityByCountry from './LeadsQualityByCountry';
import LeadsEvents from './LeadsEvents';
import Select from "react-select";

import Api from "./Api";
import DealerEngagementByTime from "./DealerEngagementByTime";
// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from 'moment'
import axios from 'axios'

const Charts = ({
                   data
               }) => {

    const [analyticsData, setAnalyticsData] = useState(data)
    const [filteredData, setFilteredData] = useState(data);
    const [allData, setAllData] = useState(Object.entries(data.leads_by_day));

    const [allActiveDealers, setAllActiveDealers] = useState(Object.entries(data.active_dealers));
    const [filteredActiveDealers, setFilteredActiveDealers] = useState(Object.entries(data.active_dealers));


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [startDateFormatted,setStartDateFormatted]= useState(null);
    const [endDateFormatted,setEndDateFormatted]= useState(null);

    const [focusedInput, setFocusedInput] = useState();

    const [qualityData, setQualityData] = useState(data.lead_country);
    const [filteredQualityData, setFilteredQualityData] = useState(data.lead_country);

    const [dealerEngagement, setDealerEngagement] = useState(data.dealer_engagement);
    const [filteredDealerEngagement, setFilteredDealerEngagement] = useState(data.dealer_engagement);

    const [leadData, setLeadData] = useState(data.leads);
    const [filteredLeadData, setFilteredleadData] = useState(data.leads);

    const [leadCountriesData, setLeadCountriesData] = useState(data.countries);
    const [filteredleadCountriesData, setFilteredLeadCountriesData] = useState(data.countries);

    const [eventData, setEventData] = useState(data.lead_events)
    const [filteredEventData, setFilteredEventData] = useState(data.lead_events)

    const [donutData, setDonutData] = useState(data.donut_leads)
    const [filteredDonutData, setFilteredDonutData] = useState(data.donut_leads)

    const [utmParameters, setUtmParameters] = useState({})
    const [utmMedium, setUtmMedium] = useState({value: "", label: ""});
    const [utmCampaign, setUtmCampaign] = useState({value: "", label: ""});
    const [utmSource, setUtmSource] = useState({value: "", label: ""});
    

    const getLeadQuality = async (startDate, endDate) => {
        try {
            const resp = await axios.get('/api/get_leads_by_country_by_quality', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    utm_campaign: utmCampaign.value,
                    utm_source: utmSource.value,
                    utmm_medium: utmMedium.value
                }})

                 if (resp.data.lead_country.length > 0) {
                     setFilteredQualityData(resp.data.lead_country)
                 }
                //
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    const getUtmParameters = async (startDate, endDate) => {
        try {
            const resp = await axios.get('/api/get_utm_parameters', {})

                 if (resp.data.source.length > 0) {
                    setUtmParameters(resp.data);
                    console.log("resp.data", resp.data)
                 }
                //
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    useEffect(() => {
        getUtmParameters();
    }, [])

    const getDataByDates = async (startDate, endDate) => {
        try {
            const resp = await axios.get('/api/get_leads_by_country', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    utm_campaign: utmCampaign.value,
                    utm_source: utmSource.value,
                    utm_medium: utmMedium.value
                }})

            setFilteredleadData(resp.data.leads)

            setFilteredLeadCountriesData(resp.data.countries)

            if (resp.data.lead_events.length > 0){
                setFilteredEventData(resp.data.lead_events)
            }
            if (resp.data.donut_leads.length > 0){
                setFilteredDonutData(resp.data.donut_leads)
            }

            if (resp.data.dealer_engagement.length > 0){
                setFilteredDealerEngagement(resp.data.dealer_engagement)
            }


            if (resp.data.leads_by_day){
                setFilteredData({...filteredData, leads_by_day: resp.data.leads_by_day})
            }
        
            //
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    const filterByUtmParameters = (utmSource, utmMedium, utmCampaign) => {

    }

    const getFilteredQualityData = (startDate, endDate) => {
        getLeadQuality(startDate, endDate);
    }

    const getFilteredLeadCountryData = (startDate, endDate) => {
        getDataByDates(startDate, endDate);
    }

    const resetData = () => {
        setFilteredData(analyticsData)
        setFilteredQualityData(qualityData);
        setFilteredEventData(eventData);
        setFilteredDonutData(donutData);
        setFilteredLeadCountriesData(leadCountriesData);
        setFilteredleadData(leadData);
        setFilteredDealerEngagement(dealerEngagement);
    }

    useEffect(() =>{
        console.log('data.donut_leads', data.donut_leads);
        console.log("filteredLeadData", filteredLeadData);
        console.log("filteredleadCountriesData", filteredleadCountriesData)
    }, [filteredleadCountriesData, filteredLeadData])

    const handleDateChange = (startDate, endDate) => {
        console.log("startDate", startDate)
        console.log("endDate", endDate)


        setStartDate(startDate);
        setEndDate(endDate);


        if (startDate != null) {
            setStartDateFormatted: startDate.format("D-MM-Y")
        }
        if (endDate != null) {
            setEndDateFormatted: endDate.format("D-MM-Y")
        }

        const start = new Date(startDate);
        const end = new Date(endDate);

        if(startDate && endDate){
            getFilteredQualityData(startDate, endDate);
            getFilteredLeadCountryData(startDate, endDate);
        }

        let leadsByDate = Object.entries(analyticsData.leads_by_day)
        let filtered = leadsByDate.filter((item) => {
            let itemDate = new Date(item[0]);
            return(itemDate>= start &&
                itemDate<= end);
        })

        let activeDealersByDate = Object.entries(analyticsData.active_dealers)
        let filteredActiveDealers = activeDealersByDate.filter((item) => {
            let itemDate = new Date(item[0]);
            return(itemDate>= start &&
                itemDate<= end);
        })

        // let dealerEngagementByDate = Object.entries(analyticsData.dealer_engagement)
        // let filtereddealerEngagement = dealerEngagementByDate.filter((item) => {
        //     let itemDate = new Date(item[0]);
        //     return(itemDate>= start &&
        //         itemDate<= end);
        // })

        let svp_activityByDate = Object.entries(analyticsData.svp_activity)
        let filteredsvp_activity = svp_activityByDate.filter((item) => {
            let itemDate = new Date(item[0]);
            return(itemDate>= start &&
                itemDate<= end);
        })

        // reducing of all objects
        const filteredObjects = filtered.reduce((a, v) => ({ ...a, [v[0]]: v[1]}), {})
        const filteredActiveDealerObjects = filteredActiveDealers.reduce((a, v) => ({ ...a, [v[0]]: v[1]}), {})
        const filteredsvp_activityObjects = filteredsvp_activity.reduce((a, v) => ({ ...a, [v[0]]: v[1]}), {})
        // const filtereddealerEngagementObjects = filtereddealerEngagement.reduce((a, v) => ({ ...a, [v[0]]: v[1]}), {})

        setFilteredData({...filteredData, 'leads_by_day': filteredObjects, 'active_dealers': filteredActiveDealerObjects, 'svp_activity': filteredsvp_activityObjects})

    }

    const handleSelect = (date) =>{
        console.log("date", date);

        // Leads By Day analytics
        let leadsByDate = Object.entries(analyticsData.leads_by_day)
        let filtered = leadsByDate.filter((item) => {
            let itemDate = new Date(item[0]);
            return(itemDate>= date.selection.startDate &&
                itemDate<= date.selection.endDate);
        })

        let activeDealersByDate = Object.entries(analyticsData.active_dealers)
        let filteredActiveDealers = activeDealersByDate.filter((item) => {
            let itemDate = new Date(item[0]);
            return(itemDate>= date.selection.startDate &&
                itemDate<= date.selection.endDate);
        })

        let dealerEngagementByDate = Object.entries(analyticsData.dealer_engagement)
        let filtereddealerEngagement = dealerEngagementByDate.filter((item) => {
            let itemDate = new Date(item[0]);
            return(itemDate>= date.selection.startDate &&
                itemDate<= date.selection.endDate);
        })

        let svp_activityByDate = Object.entries(analyticsData.svp_activity)
        let filteredsvp_activity = svp_activityByDate.filter((item) => {
            let itemDate = new Date(item[0]);
            return(itemDate>= date.selection.startDate &&
                itemDate<= date.selection.endDate);
        })

        // reducing of all objects
        const filteredObjects = filtered.reduce((a, v) => ({ ...a, [v[0]]: v[1]}), {})
        const filteredActiveDealerObjects = filteredActiveDealers.reduce((a, v) => ({ ...a, [v[0]]: v[1]}), {})
        const filteredsvp_activityObjects = filteredsvp_activity.reduce((a, v) => ({ ...a, [v[0]]: v[1]}), {})
        const filtereddealerEngagementObjects = filtereddealerEngagement.reduce((a, v) => ({ ...a, [v[0]]: v[1]}), {})

        setFilteredData({...filteredData, 'leads_by_day': filteredObjects, 'active_dealers': filteredActiveDealerObjects, 'svp_activity': filteredsvp_activityObjects, 'dealer_engagement': filtereddealerEngagementObjects})

        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
    };

    useEffect(() => {
        console.log('data', filteredData);
        console.log("startDate", startDate);
        console.log("utmSource", utmSource);
        if(utmSource.value !== "" || utmCampaign.value !== "" || utmMedium.value !== ""){
            getDataByDates(startDate, endDate);
            getLeadQuality(startDate, endDate);
        }
    }, [utmSource, utmMedium, utmCampaign])

    const updateSource = (e) => {
        setUtmSource(e);
    }

    const updateMedium = (e) => {
        setUtmMedium(e);
    }

    const updateCampaign = (e) => {
        setUtmCampaign(e);
    }

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    const minDate = moment().subtract(2, 'year');

    return (
        <Router>
            <Switch>

                <div className="columns is-multiline">
                    <div className="column is-12">


                        <DateRangePicker
                            isOutsideRange= {()=> false}
                            minDate={minDate}
                            startDate={startDate}
                            startDateId="start_date_id"
                            endDate={endDate}
                            endDateId="end_date_id"
                            onDatesChange={({ startDate, endDate }) =>
                                handleDateChange(startDate, endDate)
                            }
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                        />

                        <Select
                            id="utm_source"
                            onChange={(e) => {
                                updateSource(e)
                            }}
                            name="utm_source"
                            options={utmParameters.source}
                            value={utmSource}
                        />

                        <Select
                            id="utm_medium"
                            onChange={(e) => {
                                updateMedium(e)
                            }}
                            name="utm_medium"
                            options={utmParameters.medium}
                            value={utmMedium}
                        />

                        <Select
                            id="utm_campaign"
                            onChange={(e) => {
                                updateCampaign(e)
                            }}
                            name="utm_campaign"
                            options={utmParameters.campaign}
                            value={utmCampaign}
                        />
                        <button onClick={(e) => {resetData()}}>Reset filters</button>


                    {/*<DateRangePicker*/}
                    {/*    date={new Date()}*/}
                    {/*    ranges={[selectionRange]}*/}
                    {/*    onChange={handleSelect}*/}
                    {/*/>*/}
                    </div>

                    <div className="column">
                        <LeadsByTime data={filteredData} />
                    </div>

                    <div className="column">
                        <UsersByActivity data={filteredData} />
                    </div>

                    <div className="column">
                        <DealersByActivity data={filteredData} />
                    </div>

                    <div className="column">
                        <DealerEngagementByTime dealer_engagement={filteredDealerEngagement} />
                    </div>

                    <div className="column">
                        <h3 className={"is-size-5 bold"}>Leads quality by country</h3>
                        <LeadsQualityByCountry data={filteredQualityData} />
                    </div>

                    <div className="column">
                        <h3 className={"is-size-5 bold"}>Leads by event</h3>
                        <LeadsEvents data={filteredEventData} />
                    </div>

                    <div className="column">
                        <h3 className={"is-size-5 bold"}>Leads by country</h3>
                        <LeadsByCountry leads={filteredLeadData} countries={filteredleadCountriesData} />
                    </div>
                    <div className="column">
                        <LeadsByCountryDonut data={filteredDonutData} />
                    </div>
                </div>
                <div className="columns is-multiline">


                </div>



            </Switch>
        </Router>
    );
};

export default Charts;
