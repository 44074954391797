import ReactEcharts from "echarts-for-react";
import React from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";

const DealerEngagementByTime = ({
                                    dealer_engagement
                        }) => {

    const dateOptions = {
        title: {
            text: 'Dealer Engagement By Day'
        },
        tooltip: {
            trigger: 'axis'
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            data: Object.keys(dealer_engagement)
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: Object.values(dealer_engagement),
                type: 'line'
            }
        ]
    };

    return (
        <Router>
            <Switch>
                <ReactEcharts
                    option={dateOptions}
                    style={{ width: "600px", height: "300px" }}
                ></ReactEcharts>
            </Switch>
        </Router>
    );
};

export default DealerEngagementByTime;