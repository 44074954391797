import React, {useState} from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ReactEcharts from "echarts-for-react"



const LeadsByCountry = ({leads, countries}) => {



    const options = {
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        grid: { top: 20, right: 40, bottom: 20, left: 40 },
        xAxis: {
            type: "category",
            data: countries
        },
        yAxis: {
            type: "value"
        },
        series: [
            {
                data: leads,
                type: "bar",
                smooth: true
            }
        ],
        tooltip: {
            trigger: "axis"
        }
    }



    return (
        <Router>
            <Switch>
                <ReactEcharts
                    option={options}
                    style={{ width: "600px", height: "300px" }}
                ></ReactEcharts>


            </Switch>
        </Router>
    );
};

export default LeadsByCountry;
