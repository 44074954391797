import { Form, Formik, useField, useFormikContext } from "formik";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Async, { makeAsyncSelect } from 'react-select/async';
import ReactDOM from "react-dom";

import * as Yup from "yup";
import Api from "./Api";
import Header from "./Header";
import { Styling } from "./Styling";
import setupCSRFToken from "./setupCSRFToken";
import { SelectField } from "./SelectField";
import CanvasDraw from "react-canvas-draw";
import i18n from "./i18n"
import { useTranslation } from 'react-i18next';

export default function LeadForm({ navigationAreas, locale, drives, countries, models, dealerObjects, salesPersonObjects, lead, event, signature, doubleOptin, selectedDealer}) {
    const { t, i18n } = useTranslation();
    const [signupPage, setSignupPage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(false);
    const [resultMessage, setResultMessage] = useState({status: false});
    const [country, setCountry] = useState(0);
    const [salesPerson, setSalesPerson] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [navigationArea, setNavigationArea] = useState("");
    const [dealerObject, setDealerObject] = useState("");
    const [countryObject, setCountryObject] = useState("");
    const [navigationObject, setNavigationObject] = useState("");

    const [salesPersonObject, setSalesPersonObject] = useState("");
    const [model, setModel] = useState([]);
    const [drive, setDrive] = useState([]);
    const [savedDrives, setSavedDrives] = useState([]);
    const [dealer, setDealer] = useState("");
    const [salesPeople, setSalesPeople] = useState([]);
    const [formType, setFormType] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [csignature, setCsignature] = useState(false);
    const [csignatureError, setcsignatureError] = useState(false);
    const [dealerFromParam, setDealerFromParam] = useState(selectedDealer);

    useEffect(() => {
        setDealerFromParam(selectedDealer);
    }, [])

    const languages = [
        {value: 'en', label: 'English'},
        {value: 'de', label: 'Deutsch'},
        {value: 'sl', label: 'Slovene'}
    ]
    const [language, setLanguage] = useState("en");

    setupCSRFToken();


    const selectInputRef = useRef();
    const selectFormRef = useRef();
    const selectSalesRef = useRef();
    const selectDealerRef = useRef();
    const selectNavRef = useRef();
    const canvasRef = useRef(null);

    //selectSalesRef, selectDealerRef, selectNavRef, selectFormRef

    const ValidationSchemaFull = Yup.object().shape({
        first_name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        last_name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        email: Yup.string()
            .email("Invalid email")
            .required("Required"),
        navigation_area_id: Yup.string(),
        country_id: Yup.string().required("Required"),
        //dealer_id: Yup.string().required("Required"),
        gdpr_privacy_policy: Yup.bool().oneOf([true], "Must agree to Privacy Policy"),
        distributor_policy: Yup.bool().oneOf([true], "Must agree to Distributor Policy")
    });

    const ValidationSchemaHalf = Yup.object().shape({
        first_name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        last_name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        email: Yup.string()
            .email("Invalid email")
            .required("Required"),
        gdpr_privacy_policy: Yup.bool().oneOf([true], "Must agree to Privacy Policy"),
        distributor_policy:Yup.bool().oneOf([true], "Must agree to Distributor Policy")
    });

    const validationSchemas = [ValidationSchemaHalf, ValidationSchemaFull]

    const Checkbox = ({ label, checked, ...props }) => {
        const [field, meta, helpers] = useField(props);
        return (
            <React.Fragment>
                <label>
                    <input {...field} type="checkbox" checked={checked} />
                    {label}
                </label>
                {meta.touched && meta.error ? <p className="help is-danger">{meta.error}</p> : null}
            </React.Fragment>
        );
    }

    const InputField = ({ label, ...props }) => {
        const [field, meta, helpers] = useField(props);
        return (
            <React.Fragment>
                <label className="label" htmlFor={name}>
                    {label}
                    <input className="input" {...field} {...props} />
                </label>
                {meta.touched && meta.error ? <p className="help is-danger">{meta.error}</p> : null}
            </React.Fragment>
        );
    };

    const TextAreaField = ({ label, ...props }) => {
        const [field, meta, helpers] = useField(props);
        return (
            <React.Fragment>
                <label className="label" htmlFor={name}>
                    {label}
                    <textarea className="input" {...field} {...props} />
                </label>
                {meta.touched && meta.error ? <p className="help is-danger">{meta.error}</p> : null}
            </React.Fragment>
        );
    };

    const FocusError = () => {
        const { errors, isSubmitting, isValidating } = useFormikContext();

        useEffect(() => {
            if (isSubmitting && !isValidating) {
                let keys = Object.keys(errors);
                if (keys.length > 0) {
                    const selector = `${keys[0]}`;
                    const errorElement = document.getElementById(selector);
                    if (errorElement) {
                        errorElement.focus();
                        errorElement.scrollIntoView({ behavior: "smooth" });
                    }
                }
            }
        }, [errors, isSubmitting, isValidating]);

        return null;
    };

    function onClear(setFieldValue){

        setDealerObject("");
        setSalesPersonObject("");
        setCountry("");
        setNavigationArea("");
        setSalesPeople("");

        selectInputRef.current.select.clearValue();
        selectInputRef.current.select.setValue("");
        selectSalesRef.current.select.clearValue();
        selectSalesRef.current.select.setValue("");
        selectDealerRef.current.select.clearValue();
        selectDealerRef.current.select.setValue("");
        selectNavRef.current.select.clearValue();
        selectNavRef.current.select.setValue("");

        // selectInputRef.current[idx].select.setValue("");
        // selectInputRef.current[idx].select.removeValue();
        setFieldValue("country_id", "");
        setFieldValue("country", "");
        setFieldValue("navigation_area_id", "");
        setFieldValue("navigation_area", "");
        setFieldValue("dealer_id", "");
        setFieldValue("dealer", "");
        setFieldValue("sales_person_id", "");
        setFieldValue("model", "");
        setFieldValue("drive", "");
        setFieldValue("address", "");
        setFieldValue("zip", "");
        setFieldValue("city", "");
        setCountry("");
        setDealerObject("");
        setNavigationObject("");
        // setFieldValue("form_type", "");

    };

    function handleSelfSignup(){
       setSignupPage(true)
    }

    function onChangeCountry(value, setFieldValue, values){
        setFieldValue("country_id", value.id);
        setFieldValue("dealer_id", "");
        setFieldValue("sales_person_id", "");
        setCountryObject(value)
        setCountry(value);
        setDealerObject("");
        selectDealerRef.current.select.clearValue();
        selectDealerRef.current.select.setValue("");
        // const data = Api.getDealers(value.id);
        // setDealers(data);
    };

    function onChangeNavigationArea(value, setFieldValue, values){
        setFieldValue("navigation_area_id", value.id)
        setFieldValue("dealer_id", "");
        setFieldValue("sales_person_id", "");
        setNavigationArea(value);
        setNavigationObject(value);

        const data = Api.getDealers(value.id);
        setDealers(data);
    }

    function handleCanvasChange(value, values){

        if(value.lines.length > 0){
            setCsignature(true);
        }
    }

    function onChangeDealer(value, setFieldValue) {
        //
        //setFieldValue("dealer_id", value);
        setFieldValue("dealer_id", value.id);
        setFieldValue("sales_person_id", "");
        setDealer(value.id);
        setDealerObject(value);
        const data = Api.getSalesPeople(value.id);
        setSalesPeople(data);
    };

    function onChangeFormType(value, setFieldValue, selectedDealer = null){
        setFieldValue("form_type", value.id);
        setFormType(value.value);
        const data = Api.getDealers();
        setDealers(data);
        setDealerObject(data[0]);
    };

    function onChangeSalesPerson(value, setFieldValue){

        setFieldValue("sales_person_id", value.id)
        // setSalesPerson(value.id)
        setSalesPersonObject(value);

    }
    function setLead(resp, setFieldValue){
        setFieldValue("first_name", resp.data.lead.first_name);
        setFieldValue("last_name", resp.data.lead.last_name);
        setFieldValue("phone", resp.data.lead.phone);
        const filteredCountry = countries.filter(c => c.id === resp.data.lead.country_id)[0]
        const filteredNavigation = countries.filter(c => c.id === resp.data.lead.navigation_area_id)[0]

        setFieldValue("country_id", filteredCountry.id);
        setCountryObject(filteredCountry);
        setNavigationObject(filteredNavigation);
        setNavigationArea(countries[resp.data.lead.navigation_area_id - 1]);
        setFieldValue("navigation_area_id", filteredNavigation.id)
        setDealerObject(dealerObjects.filter((e) => e.id === resp.data.lead.dealer_id));
        setFieldValue("dealer_id", dealerObjects.filter((e) => e.id === resp.data.lead.dealer_id))
        if (salesPersonObjects) {
            setSalesPersonObject(salesPersonObjects.filter((e) => e.id === resp.data.lead.sales_person_id));
        }

        const modelsArray = resp.data.lead.model.split(", ");
        setFieldValue("model", resp.data.lead.model);
        const drivesArray = resp.data.lead.drive.split(", ");

        const loadedModels = [];

        if (modelsArray.length > 1) {
            modelsArray.forEach(function (item) {
                loadedModels.push(
                    models.filter((e) => e.value === item)[0]
                );
            });

            setFieldValue("model", loadedModels);
        } else {

            modelsArray.forEach(function (item) {
                loadedModels.push(
                    models.filter((e) => e.value === item)
                );
            });

            setFieldValue("model", loadedModels[0]);
        }


        const loadedDrives = [];

        if (drivesArray.length > 1) {
            drivesArray.forEach(function (item) {
                loadedDrives.push(
                    drives.filter((e) => e.value === item)[0]
                );
            });

            setFieldValue("drive", loadedDrives);
        } else {
            drivesArray.forEach(function (item) {
                loadedDrives.push(
                    drives.filter((e) => e.value === item)
                );
            });

            setFieldValue("drive", loadedDrives[0]);
        }


        setSavedDrives(loadedDrives)
        setFieldValue("address", resp.data.lead.address);
        setFieldValue("zip", resp.data.lead.zip);
        setFieldValue("city", resp.data.lead.city);
        setFieldValue("comment", resp.data.lead.comment);
    }

    async function findLead(email, setFieldValue){
        try {
            const resp = await axios.get('/api/get-lead', {
                params: {
                    email: email
                }});

            if(resp.data.lead === null) {
                setUpdateStatus(false);
            } else {

                setUpdateStatus(true);
                setLead(resp, setFieldValue);
                //
            }
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    function submitLead(values){

    }
    function handleSubmit(values, {resetForm, setSubmitting, setFieldValue}){
        let data;
        setSubmitting(true);
        if (canvasRef.current) {
            values.signature_image = canvasRef.current.canvasContainer.children[1].toDataURL();
        }

        if (!csignature && signature){
            setcsignatureError(true);
            setSubmitting(false);
            return false;
        }


        values.double_optin_active = doubleOptin;

        if(values.model[0]) {
            let modelsArray = [];
            values.model.forEach(function (obj) {
                modelsArray.push(obj.value);
            });
            values.model = modelsArray.join(', ');
        }

        if(values.drive[0]) {
            let drivesArray = [];
            values.drive.forEach(function (obj) {
                drivesArray.push(obj.value);
            });
            values.drive = drivesArray.join(', ');

        }

        if(updateStatus === true){

            // we don't need to double optin for existing users!
            values.double_optin_active = false;

            axios.post("/api/update-lead", {lead: {...values}})
                .then(response => {
                    setResultMessage({status:true});
                    setFormType("");
                    resetForm();
                    onClear(setFieldValue);
                })
                .catch(error => {
                    console.log(error)
                })
                .then(() => {});
        } else {
            axios.post("/leads", {lead: {...values}})
                .then(response => {
                    setResultMessage({status:true});
                    setFormType("");
                    resetForm();
                    onClear(setFieldValue);
                })
                .catch(error => {
                    console.log(error)
                    if(error.response.status === 401 || error.response.status === 402 || error.response.status === 422){
                        setErrorMessage(true)
                    }
                })
                .then(() => {});

        }

        return data;
    };

    const isEmpty = str => {
        return !str || 0 === str.length;
    };

    let schema = 0
    if (formType === "visitor" || formType === "client") {
        schema = 1
    } else {
        schema = 0
    }

    const formTypes = [{
        id: 0, value: 'client', label: t('form_types.client')},
        {id: 2, value: 'press', label: t('form_types.press')},
        {id: 3, value: 'supplier', label: t('form_types.supplier')},
    ]


    function handleLanguageChange(value, setFieldValue) {
        i18n.changeLanguage(value.value);
    }

    let leadEmail = ''
    let leadfirstName = ''
    let leadlastName = ''
    let navigation_area_id = ''
    let dealer_id = ''
    let leadPhone = ''
    let modelArray = []
    let country_id = ''
    let address = ''
    let zip = ''
    if(lead){
        const models = lead.model.split(', ')
        models.forEach((item) => {
            modelArray.push({ value: item, label: item })
        })
        leadfirstName = lead.first_name
        leadlastName = lead.last_name
        leadEmail = lead.email
        navigation_area_id = lead.navigation_area_id
        dealer_id = lead.dealer_id
        leadPhone = lead.phone
        country_id = lead.country_id
        address = lead.address
        zip = lead.zip
    }

    return (
        <Formik
            initialValues={{
                first_name: leadfirstName,
                last_name: leadlastName,
                email: leadEmail,
                country_id: country_id,
                navigation_area_id: navigation_area_id,
                dealer_id: dealer_id,
                gdpr_privacy_policy: false,
                distributor_policy: false,
                phone: leadPhone,
                sales_person_id: "",
                comment: "",
                drive: [],
                model: modelArray,
                form_type: "",
                city: "",
                address: address,
                zip: ""
            }}
            validationSchema={validationSchemas[schema]}
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            {({
                values,
                dirty,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
                resetForm
            }) => (
                <Styling>
                        <Header signature={signature} />
                        <div className="container">
                            <div className="columns">
                                <div className="column">
                                    <Form className="form w-100">

                                        <div className="columns">


                                           <div className="column">
                                               <label className="label">{t('language')}</label>
                                            <Select
                                                id="language"
                                                onBlur={() => setFieldTouched("language", true)}
                                                onChange={value => {
                                                    if (value) {
                                                        handleLanguageChange(value, setFieldValue)
                                                    }
                                                }}
                                                name="language"
                                                options={languages}
                                                value={values.language}
                                            />
                                           </div>
                                        </div>

                                        <div className="columns">
                                            <div className="column">
                                                <label className="label">{t('form_type')}</label>
                                                <Select
                                                    ref={selectFormRef}
                                                    id="form_type"
                                                    onBlur={() => setFieldTouched("form_type", true)}
                                                    onChange={value => {
                                                        if (value) {
                                                            onChangeFormType(value, setFieldValue, dealerFromParam)
                                                        }
                                                    }}
                                                    name="form_type"
                                                    options={formTypes}
                                                    value={values.formType}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            {formType !== "" && (
                                                <React.Fragment>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <InputField name="email" type="text" label={t('email')} id="email"
                                                                        value={values.email}
                                                                        autoComplete="new-password"
                                                                        onBlur={() => {
                                                                            setFieldValue("email", values.email.toLowerCase());
                                                                            findLead(values.email, setFieldValue)
                                                                        }}
                                                            />
                                                        </div>
                                                        <div className="column">
                                                            <InputField name="phone" type="text" label={t('phone_number')}
                                                                        id="phone"
                                                                        autoComplete="nope"
                                                                        value={values.phone}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <InputField name="first_name" type="text" label={t('first_name')}
                                                                        id="first_name"
                                                                        autoComplete="nope"
                                                                        value={values.first_name}/>
                                                        </div>

                                                        <div className="column">
                                                            <InputField name="last_name" type="text" label={t('last_name')}
                                                                        id="last_name"
                                                                        autoComplete="nope"
                                                                        value={values.last_name}/>
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div>
                                            {(formType === "visitor" || formType === "client") && (
                                                <React.Fragment>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="label" htmlFor="country_id">
                                                                {t('country')}
                                                            </label>
                                                            <Select
                                                                backspaceRemovesValue={true}
                                                                ref={selectInputRef}
                                                                id="country_id"
                                                                onBlur={() => setFieldTouched("country_id", true)}
                                                                onChange={value => {
                                                                    if (value) {
                                                                        onChangeCountry(value, setFieldValue)
                                                                    }
                                                                }}
                                                                name="country_id"
                                                                options={countries}
                                                                getOptionLabel={option => option.name}
                                                                getOptionValue={option => option.id}
                                                                isSearchable={true}
                                                                value={countryObject}
                                                            />
                                                            {touched.country_id && errors.country_id ? (
                                                                <p className="help is-danger">{errors.country_id}</p>
                                                            ) : null}
                                                        </div>
                                                        <div className="column">
                                                            <label className="label" htmlFor="navigation_area_id">
                                                                {t('navigation_area')}
                                                            </label>
                                                            <Select
                                                                backspaceRemovesValue={true}
                                                                ref={selectInputRef}
                                                                id="navigation_area_id"
                                                                onBlur={() => setFieldTouched("navigation_area_id", true)}
                                                                onChange={value => {
                                                                    if (value) {
                                                                        onChangeNavigationArea(value, setFieldValue)
                                                                    }
                                                                }}
                                                                name="navigation_area_id"
                                                                options={navigationAreas}
                                                                getOptionLabel={option => option.name}
                                                                getOptionValue={option => option.id}
                                                                isSearchable={true}
                                                                value={navigationObject}
                                                            />

                                                            {touched.navigation_area_id && errors.navigation_area_id ? (
                                                                <p className="help is-danger">{errors.navigation_area_id}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="label" htmlFor="dealer">
                                                                {t('dealer')}
                                                            </label>
                                                            <Select
                                                                ref={selectDealerRef}
                                                                id="dealer_id"
                                                                onBlur={() => setFieldTouched("dealer_id", true)}
                                                                onChange={value => {
                                                                    if (value) {
                                                                        onChangeDealer(value, setFieldValue)
                                                                    }
                                                                }}
                                                                name="dealer_id"
                                                                options={dealers}
                                                                getOptionLabel={option => `${option.first_name}`}
                                                                getOptionValue={option => option.name}
                                                                value={dealerObject}
                                                            />
                                                            {touched.dealer_id && errors.dealer_id ? (
                                                                <p className="help is-danger">{errors.dealer_id}</p>
                                                            ) : null}
                                                        </div>
                                                        <div className="column">
                                                            <label className="label" htmlFor="navigation_area_id">
                                                                {t('sales_person')}
                                                            </label>
                                                            <Select
                                                                ref={selectSalesRef}
                                                                id="sales_person_id"
                                                                onBlur={() => setFieldTouched("sales_person_id", true)}
                                                                onChange={value => {
                                                                    if (value) {
                                                                        onChangeSalesPerson(value, setFieldValue)
                                                                    }
                                                                }}
                                                                name="sales_person_id"
                                                                options={salesPeople}
                                                                getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                                                                getOptionValue={option => option.id}
                                                                value={salesPersonObject}
                                                                defaultValue={salesPersonObject}
                                                                isDisabled={isEmpty(dealer)}
                                                            />
                                                            {touched.sales_person_id && errors.sales_person_id ? (
                                                                <p className="help is-danger">{errors.sales_person_id}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <InputField name="zip" type="text" label={t('zip')}
                                                                        value={values.zip}/>
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <InputField name="address" type="text" label={t('address')}
                                                                        value={values.address}/>
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <InputField name="city" type="text" label={t('city')}
                                                                        value={values.city}/>
                                                        </div>
                                                    </div>

                                                    <div className="columns">
                                                        <div className="column">
                                                            <TextAreaField rows="5" name="comment" className="textarea"
                                                                           label={t('comment')} value={values.comment}/>
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <label className="label" htmlFor="model">
                                                                {t('boat_model')}
                                                            </label>
                                                            <SelectField
                                                                id="model"
                                                                name="model"
                                                                isClearable={true}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                touched={touched.model}
                                                                backspaceRemovesValue={true}
                                                                options={models}
                                                                isMulti={true}
                                                                value={values.model}
                                                            />

                                                            {touched.model && errors.model ? (
                                                                <p className="help is-danger">{errors.model}</p>
                                                            ) : null}
                                                        </div>
                                                        <div className="column ">
                                                            <label className="label" htmlFor="drive">
                                                                {t('drive_type')}
                                                            </label>
                                                            <SelectField
                                                                isClearable={true}
                                                                id="drive"
                                                                name="drive"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                touched={touched.drive}
                                                                backspaceRemovesValue={true}
                                                                options={drives}
                                                                isMulti={true}
                                                                value={values.drive}
                                                            />
                                                            {touched.drive && errors.drive ? (
                                                                <p className="help is-danger">{errors.drive}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            {formType !== "" && (
                                                <React.Fragment>
                                                    <div className="columns">
                                                        <div className="column">
                                                            <Checkbox
                                                                id="gdpr_privacy_policy"
                                                                label={t('distributor_policy')}
                                                                name="gdpr_privacy_policy"
                                                                type="checkbox"
                                                                checked={values.gdpr_privacy_policy}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="columns">
                                                        <div className="column">

                                                                <Checkbox
                                                                    id="distributor_policy"
                                                                    label={<a href='https://www.greenlinehybrid.com/en/data-protection-policy' target='_blank'>I accept your Privacy Policy</a>}
                                                                    name="distributor_policy"
                                                                    type="checkbox"
                                                                    checked={values.distributor_policy}
                                                                />

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        {signature === true && formType !== "" && (
                                            <React.Fragment>
                                                <div className="columns">
                                                    <div className="column mt-5">
                                                        <label className="label" htmlFor="model">
                                                            {t('sign')}
                                                        </label>
                                                        <CanvasDraw
                                                            className="mt-5"
                                                            ref={canvasRef}
                                                            onChange={handleCanvasChange}
                                                            brushColor={"#333"}
                                                            brushRadius={2}
                                                            lazyRadius={2}
                                                            canvasWidth={320}
                                                            canvasHeight={150}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}

                                        {signature === true && formType !== "" && csignatureError === true && (
                                            <React.Fragment>
                                                <div className="mb-3">
                                                    {t('or_sign')}
                                                </div>
                                            </React.Fragment>
                                        )}
                                        <button className="button is-primary" type="submit"
                                                disabled={formType === "" || !dirty}>
                                            {t('submit')}
                                        </button>

                                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                            <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                                        <FocusError/>
                                    </Form>
                                </div>
                            </div>

                            {resultMessage.status === true && (
                                <div className="notification is-success"> {t('thanks')}</div>
                            )}

                            {errorMessage === true && (
                                <div className="notification is-warning"> {t('relogin')}</div>
                            )}
                        </div>


                </Styling>
            )}
        </Formik>
    );
};

