import ReactEcharts from "echarts-for-react";
import React from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";

const UsersByActivity = ({
                         data
                     }) => {
    const dateOptions = {
        title: {
            text: 'SVP Members Login Activity'
        },
        tooltip: {
            trigger: 'axis'
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            data: Object.keys(data.svp_activity)
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: Object.values(data.svp_activity),
                type: 'line'
            }
        ]
    };

    return (
        <Router>
            <Switch>
                <ReactEcharts
                    option={dateOptions}
                    style={{ width: "600px", height: "300px" }}
                ></ReactEcharts>
            </Switch>
        </Router>
    );
};

export default UsersByActivity;