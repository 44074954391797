import React, {useState} from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ReactEcharts from "echarts-for-react"



const LeadsByCountryDonut = ({
                data
             }) => {

    const options = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                name: 'Leads from: ',
                type: 'pie',
                radius: ['50%'],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                },
                data: data

            }
        ]
    };

    return (
        <Router>
            <Switch>
                <ReactEcharts
                    option={options}
                    style={{ width: "600px", height: "400px" }}
                ></ReactEcharts>


            </Switch>
        </Router>
    );
};

export default LeadsByCountryDonut;
