// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import { bulma } from "bulma";
import "./scss/application.scss";
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");


ReactRailsUJS.useContext(componentRequireContext);

const range = (start, stop) => Array.from({ length: stop - start + 1 }, (_, i) => start + i)

$(document).on('turbolinks:load', function() {

    $('.submit-custom-label').on('touch click', function() {

        const parentDiv = $(this).parent();
        const lead_id = parentDiv.find('input[name="lead_id"]').val();
        const name = parentDiv.find('input[name="name"]').val();
        if(name == "" || lead_id == ""){
            return false;
        }

        $.ajax({
            type: "POST",
            url: "/labels/create",
            data: {label: {lead_id: lead_id, name: name}},
            success: function (data, textStatus, jqXHR) {
                parentDiv.classList.remove('is-active')
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log('failure')
            }
        })
    });

    (document.querySelectorAll('.open-label-modal') || []).forEach(($trigger) => {
        const modal = $trigger.dataset.target;
        const $target = document.getElementById(modal);

        console.log($target)


        $trigger.addEventListener('click', () => {
            openLabelModal($target);
        });
    });

    $('.update_stages').on('change', function(){
        const parentDiv = $(this);
        const lead_id = parentDiv.data('lead-id');

        $.ajax({
            type: "POST",
            url: "/leads/update_stage",
            data: {lead: {id: lead_id, business_stage: $(this).val()}},
            success: function (data, textStatus, jqXHR) {
                console.log('updated')
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log('failure')
                $(this).val($(this).data('old-stage'))
            }
        })
    });

    function openLabelModal($el){
        console.log($el);
        $el.classList.add('is-active');
    }

    // Functions to open and close a modal
    function openModal($el) {
        $el.classList.add('is-active');
    }

    function closeModal($el) {
        $el.classList.remove('is-active');
    }

    function closeAllModals() {
        (document.querySelectorAll('.modal') || []).forEach(($modal) => {
            closeModal($modal);
        });
    }

    // Add a click event on buttons to open a specific modal
    (document.querySelectorAll('.js-modal-trigger') || []).forEach(($trigger) => {
        const modal = $trigger.dataset.target;
        const $target = document.getElementById(modal);

        $trigger.addEventListener('click', () => {
            openModal($target);
        });
    });

    // Add a click event on various child elements to close the parent modal
    (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
        const $target = $close.closest('.modal');

        $close.addEventListener('click', () => {
            closeModal($target);
        });
    });

    // Add a keyboard event to close all modals
    document.addEventListener('keydown', (event) => {
        const e = event || window.event;

        if (e.keyCode === 27) { // Escape key
            closeAllModals();
        }
    });

    $('.close-tooltip').on('touch click', function(){
        $('.tooltip').hide();
    })

    $('.ratings-filter .rating').on('touch click', function() {
        var rating = $(this).data('rating');
        $('.rating-form-field').val(rating);
    });
    $('.rating-icon').on('touch click', function(){
        $('.tooltip').toggle();
    })


    $('.ratings .rating').on('touch click', function(){
        console.log($(this).data('rating'));
        var $this = $(this);
        var rating = $(this).data('rating');
        var lead_id = $(this).data('lead-id');

        var arr = range(1,rating)
        arr.forEach((item) => {
            var d = $(this).parent().find('*[data-rating="'+item+'"]');
            if(d.hasClass('has-rating')) {
                //d.removeClass('filled').addClass('notfilled');
            } else {
                d.removeClass('notfilled').addClass('filled has-rating');
            }
        })

        if($(this).hasClass('has-rating')){
            var ToDisableArray = range(rating+1, 5)
            ToDisableArray.forEach((item) => {
                var d = $(this).parent().find('*[data-rating="'+item+'"]');
                d.removeClass('filled').addClass('notfilled');
            })
        }

        $.ajax({
            type: "POST",
            url: "/leads/update_rating",
            data: {id: lead_id, rating: rating },
            success: function(data, textStatus, jqXHR){
                console.log('success', data)

            },
            error: function(jqXHR, textStatus, errorThrown){
                console.log('failure')
            }
        })
    })

    $('.ratings .rating').on('mouseover', function(){

        var rating = $(this).data('rating');
        var arr = range(1,rating)
        arr.forEach((item) => {
            var d = $(this).parent().find('*[data-rating="'+item+'"]');
            if(d.hasClass('has-rating')) {
                //d.removeClass('filled').addClass('notfilled');
            } else {
                d.removeClass('notfilled').addClass('filled');
            }
        })

        if($(this).hasClass('has-rating')){
            var ToDisableArray = range(rating+1, 5)
            ToDisableArray.forEach((item) => {
                var d = $(this).parent().find('*[data-rating="'+item+'"]');
                d.removeClass('filled').addClass('notfilled');
            })
        }
    });

    $('.ratings .rating').on('mouseout', function(){

        var rating = $(this).data('rating');
        var arr = range(1,rating)
        arr.forEach((item) => {
            var d = $(this).parent().find('*[data-rating="'+item+'"]');
            if(d.hasClass('has-rating')){
                // do nothing
                d.removeClass('notfilled').addClass('filled');
            } else {
                d.removeClass('filled').addClass('notfilled');
            }
        })
    });


    $('.open-notifications-center').on('touch click', function() {
        $('.notification-center').toggleClass('active');
        $('.notification-center').toggle();
        $('.overlay').toggleClass('active')
    });

    $('.toggler').on('touch click', function() {

        let toggle = '#list-'+$(this).data('toggler')
        $(this).find('svg').toggleClass('fa-chevron-down fa-chevron-up')
        $(toggle).toggleClass('active');

    })

    $('.overlay').on('touch click', function() {
        if($('.notification-center').hasClass('active')) {

            $('.notification-center').toggleClass('active');
            $('.notification-center').toggle();
            $('.overlay').toggleClass('active')
        }
    })

    $('input[name="delete"]').val(false);

    $('#deleteButton').on('touch click', function(){
        $('input[name="delete"]').val(true);
    });

    $('#autoAssignButton').on('touch click', function(){
        $('input[name="autoassign"]').val(true);
    });
    $('#open-search').on('touch click', function(){
        $('#filter-form').hide();
        $('#form-update').hide();
        $('#search-form').toggle();
    });
    $('#open-filter').on('touch click', function(){
        $('#filter-form').toggle();
        $('#form-update').hide();
        $('#search-form').hide();
    });
    $('#open-update').on('touch click', function(){
        $('#filter-form').hide();
        $('#search-form').hide();


        $('#form-update').toggle();
    });

    $(".select-all").on(' touch click', function(){


    });

    $('.select-all').change(function () {
        $('.lead_ids input[type="checkbox"]').prop('checked', this.checked);
    });

    function activateCheckbox(){
        $("tbody input[type=checkbox]").prop("checked",true)
    }

    function deactivateCheckbox(){
        $("tbody input[type=checkbox]").prop("checked",false)
    }


    var form = document.getElementById('form');
    var checkBoxes = $('#form').find('input[type="checkbox"]');
    var checkListBoxes = $('#form').find('.lead_ids input[type="checkbox"]');
    var count = 0;

    var divBoxesChecked = document.getElementById('boxesChecked');
    if(divBoxesChecked) {
        divBoxesChecked.innerHTML = 0;

        $(checkListBoxes).on('click', function () {
            // but we only count the list
            $.each(checkListBoxes, function (i) {
                if (checkListBoxes[i].checked) {
                    count++;
                }
            });
            divBoxesChecked.innerHTML = count;
            count = 0;


        });
    }

    $('.select-all').on('change', function() {
        if( $(this).prop('checked') ) {

        // but we only count the list
            $.each(checkListBoxes, function (i) {
                if (checkListBoxes[i].checked) {
                    count++;
                }
            });
        divBoxesChecked.innerHTML = count;
        count = 0;
             } else {
            count = 0;
            divBoxesChecked.innerHTML = count;
        }


    });


});
require("trix")
require("@rails/actiontext")