import React, {useState} from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ReactEcharts from "echarts-for-react"



const LeadsQualityByCountry = ({
                data
             }) => {




    const ratings = data.map(({ average_rating }) => average_rating);
    const countries = data.map(({ country }) => country);

    const options = {

        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        tooltip: {
            trigger: 'axis'
        },
        grid: { top: 20, right: 40, bottom: 20, left: 40 },
        xAxis: {
            type: "category",
            data: countries,
            axisLabel: { interval: 0, rotate: 30 }
        },
        yAxis: {
            type: "value"
        },
        series: [
            {
                data: ratings ,
                type: "bar",
                smooth: true
            }
        ],
    }

    return (
        <Router>
            <Switch>
                <ReactEcharts
                    option={options}
                    style={{ width: "600px", height: "450px", padding: "" }}
                ></ReactEcharts>


            </Switch>
        </Router>
    );
};

export default LeadsQualityByCountry;
