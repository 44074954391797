import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            language: "Please select your language",
            form_types: {
                client: "Client",
                visitor: "Visitor",
                press: "Press",
                supplier: "Supplier"
            },
            relogin:"Refresh the page and login again",
            title: 'Signup for your Dealer',
            with_signature: "With signature",
            hear_from_you: "We'd love to hear from you",
            form_type:"I am a... ",
            zip: "ZIP",
            email: "Email",
            phone_number: "Phone number",
            first_name: "First Name",
            last_name: "Last Name",
            country: "Country",
            navigation_area: "Navigation Area",
            dealer: "Dealer",
            sales_person: "Sales Person",
            address: "Address",
            city: "City",
            comment: "Comment",
            boat_model: "Boat Model",
            drive_type: "Drive Type",
            distributor_policy: "I agree that my data will be stored and shared with the appropriate distributor based on my navigation area or residence country so that they can contact me for sales and marketing",
            privacy_policy: "I accept your Privacy Policy",
            sign: "Please sign here for GDPR purposes",
            or_sign: "Please sign here if you haven't",
            submit: "Submit",
            thanks: "The form has been submitted. Thank you."
        }
    },
    de: {
        translation: {
            language: "Bitte wählen Sie Ihre Sprache",
            form_types: {
                client: "Kunden",
                visitor: "Gast",
                press: "Presse",
                supplier: "Lieferant"
            },
            relogin:"Refresh the page and login again",
            zip:"PLZ",
            title: 'Melden Sie sich für Ihren Händler an',
            with_signature: "Mit Unterschrift",
            hear_from_you: "Wir würden uns freuen, von Ihnen zu hören",
            form_type: "Ich bin ein... ",
            email: "Email",
            phone_number: "Telefonnummer",
            first_name: "Vorname",
            last_name: "Nachname",
            country: "Land",
            navigation_area: "Navigationsbereich",
            dealer: "Händler",
            sales_person: "Verkäufer",
            address: "Adresse",
            city: "Stadt",
            comment: "Kommentar",
            boat_model: "Bootsmodell",
            drive_type: "Antriebsart",
            distributor_policy: "Ich stimme zu, dass meine Daten an den Vertragshändler von greenline in der Nähe meines Wohn- oder Navigationslandes weitergegeben werden",
            privacy_policy: "Akzeptieren Sie unsere Datenschutzrichtlinie",
            sign: "Bitte unterschreiben Sie hier für DSGVO-Zwecke",
            or_sign: "Bitte unterschreiben Sie hier, falls noch nicht geschehen",
            submit: "Einsenden",
            thanks: "Das Formular wurde übermittelt. Vielen Dank."
        }
    },
    sl: {
        translation: {
            language: "Prosimo, izberite svoj jezik",
            form_types: {
                client: "Stranka",
                visitor: "Obiskovalec",
                press: "Pritisnite",
                supplier: "dobavitelj"
            },
            relogin:"Refresh the page and login again",
            zip: 'Zip',
            title: 'Prijavite se za svojega trgovca',
            with_signature: "S podpisom",
            hear_from_you: "Radi bi slišali od vas",
            form_type:"Jaz sem... ",
            email: "E-naslov",
            phone_number: "Telefonska številka",
            first_name: "Ime",
            last_name: "Priimek",
            country: "Država",
            navigation_area: "Navigacijsko območje",
            dealer: "Prodajalec",
            sales_person: "Prodajalec",
            address: "naslov",
            city: "mesto",
            comment: "Komentar",
            boat_model: "Model čolna",
            drive_type: "Vrsta pogona",
            distributor_policy: "Strinjam se, da bodo moji podatki posredovani pogodbenemu distributerju Greenline v bližini moje države bivanja ali države plovbe",
            privacy_policy: "Sprejmite našo politiko zasebnosti",
            sign: "Prosimo, podpišite se tukaj za namene GDPR",
            or_sign: "Prosimo, podpišite se tukaj, če še niste",
            submit: "Pošlji",
            thanks: "Obrazec je oddan. Hvala vam."
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;