import ReactEcharts from "echarts-for-react";
import React from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";

const OtherLeadsByTime = ({
                            data
                        }) => {

    const option = {
        title: {
            text: 'Leads Activity'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Unconfirmed', 'Unassigned']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.unassigned_leads_date
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: 'Unconfirmed leads',
                type: 'line',
                stack: 'Total',
                data: data.unconfirmed_leads_count
            },
            {
                name: 'Unassigned leads',
                type: 'line',
                stack: 'Total',
                data: data.unassigned_leads_count
            }
        ]
    };

    return (
        <Router>
            <Switch>
                <ReactEcharts
                    option={option}
                    style={{ width: "600px", height: "300px" }}
                ></ReactEcharts>
            </Switch>
        </Router>
    );
};

export default OtherLeadsByTime;