import React from "react";
import {useTranslation} from "react-i18next";

const Header = props => {
    const {signature} = props;
    const {event} = props;
    const { t, i18n } = useTranslation();

    console.log(event);

  return (
    <React.Fragment>
      <div className="container">
      <div className="columns">
      <div className="column">
        <h1 className="title is-1">

            {t('title')}
                              </h1>
          {signature &&
          <div className={'is-info'}>{t('with_signature')}</div>
          }
      </div>
    </div>
      <div className="columns">
        <div className="column">
          <h3 className="title is-6">
              {t('hear_from_you')}
                              </h3>
        </div>

    </div>
      </div>
  </React.Fragment>
)}

export default Header;


