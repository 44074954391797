import styled from "styled-components";

export const Styling = styled.div`
     @media only screen and (max-width:1024px){
     .container {
            padding:0 20px;
            margin-bottom:20px;
}       
 
 }
 @media only screen and (max-width:768px){
 .container {
            padding:0 0;
            margin-bottom:20px;
}  
}
    .form {
        @media only screen and (max-width:1024px){
            padding:0 20px;
        }
        display: flex;
        flex-direction: column;
        button {
            &:disabled {
                opacity: 40%;
                cursor: inherit;
            }
        }
        .row {
            margin-bottom: 1rem;
            display: flex;
            .column {
                flex: 1;
            }
        }
    }
`;
