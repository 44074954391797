import React, {useState} from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import LeadForm from "./LeadForm";
import LeadSuccess from "./LeadSuccess";
function App({
    formTypes,
    drives,
    countries,
    navigationAreas,
    models,
    dealerObjects,
    salesPersonObjects,
    lead,
    event
}){

    return (
            <Router>
                <Switch>
                    <LeadForm
                        path="/"
                        drives={drives}
                        countries={countries}
                        navigationAreas={navigationAreas}
                        models={models}
                        dealerObjects={dealerObjects}
                        lead={lead}
                        event={event}
                        salesPersonObjects={salesPersonObjects}
                        signature={false}
                        doubleOptin={true}
                    />
                </Switch>
            </Router>
    );
};

export default App;
