import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';

const Test = (props) => {
    const [data, setData] = useState('No result');



    useEffect(() => {
        if(data !== 'No result') {
            window.location.replace(data)
        }
    }, [data])
    return (
        <>
            <div className="qrcode-video">
                <QrReader
                    constraints={
                        { facingMode: { exact: "environment" } }
                    }
                    onResult={(result, error) => {
                        if (!!result) {
                            setData(result?.text);
                        }

                        if (!!error) {
                            console.info(error);
                        }
                    }}
                    style={{ width: '100%', paddingTop:'0', position:'relative' }}
                />
            </div>
        </>
    );
};

export default Test