import React, {useState} from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ReactEcharts from "echarts-for-react"



const LeadsQualityByCountry = ({
                data
             }) => {


    const events = data.map(({ event_name }) => event_name);
    const lead_count = data.map(({ lead_count }) => lead_count);

    const options = {

        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        tooltip: {
            trigger: 'axis'
        },
        grid: { top: 20, right: 40, bottom: 20, left: 40 },
        xAxis: {
            type: "category",
            data: events,
            axisLabel: { interval: 0 }
        },
        yAxis: {
            type: "value"
        },
        series: [
            {
                data: lead_count ,
                type: "bar",
                smooth: true
            }
        ],
        tooltip: {
            trigger: "axis"
        }
    }



    return (
        <Router>
            <Switch>
                <ReactEcharts
                    option={options}
                    style={{ width: "600px", height: "300px" }}
                ></ReactEcharts>


            </Switch>
        </Router>
    );
};

export default LeadsQualityByCountry;
