import axios from "axios";

export default {

    async fetchLeadQuality(startDate, endDate){


        let result = [];
        return await axios.get("/api/get_leads_by_country_by_quality", {
            params: {
                startDate: startDate,
                endDate: endDate
            }})
            .then(response => {
                result.push(...response.data.lead_country)
            })
            .catch(error => {console.warn(error)})
            .then(() => {
                // always executed
            });

        return result;

    },
    async fetchNewTextC(email) {
        let result = [];
        return await axios.get("/api/get-lead", {
            params: {
                email: email
            }})
            .then(response => {response.data.lead})
            .catch(error => console.warn(error));
    },

    getDealers(value) {
        let result = [];
        axios
            .get("/api/dealers", {
                params: {
                    id: value
                }
            })
            .then(response => {
                console.log(response);
                result.push(...response.data.dealers);
            })
            .catch(error => {
                console.log(error);
            })
            .then(() => {
                // always executed
            });
        return result;
    },

    async getLead(value) {
        let result = [];
        return await axios
            .get("/api/get-lead", {
                params: {
                    email: value
                }
            })
            .then(response => {
                result.push(...response.data.lead);
            })
            .catch(error => {
                console.log(error);
            })
            .then(() => {
                // always executed
            });
    },

    getSalesPeople(value) {
        let result = [];
        axios
            .get("/sales_people", {
                params: {
                    id: value
                }
            })
            .then(response => {
                result.push(...response.data.sales_people);
            })
            .catch(error => {
                console.log(error);
            })
            .then(() => {
                // always executed
            });
        return result;
    }
};
